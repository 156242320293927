import AUTOMATION from "./Assets/Projects/3automation.jpg";
import COAST from "./Assets/Projects/coast.jpg";
import DROPSHIP from "./Assets/Projects/dropship.jpg";
import GOOD_FOOD_DISCOUNTS from "./Assets/Projects/goodFoodDiscounts.png";
import GUAMEDIA from "./Assets/Projects/guamedia.jpg";
import HSP from "./Assets/Projects/hsp.png";
import COSMOS from "./Assets/Projects/cosmos.jpg";
import TECHSPEC from "./Assets/Projects/techspec.png";
import TRAVELWELL from "./Assets/Projects/travelwell.png";
import WORLD from "./Assets/Projects/world.jpeg";
import CAR from "./Assets/Projects/carsmall.png"
import DISCORD from "./Assets/Projects/discord.png"




export const PROJECTS = [
  {
    image: WORLD,
    name: "Automated Geoguesser",
    technologyUsed: "Python, Keras, Tensorflow",
    description: "An automated geoguesser that can guess the location of an image. Trained a sample of over 20000 images achieveing 33% classification accuracy, which is comparable to Google's model of 40%.",
    url: "https://github.com/ajand2003/Geoguesser",
  },
  {
    image: CAR,
    name: "Autonomous Arduino Car",
    technologyUsed: "Python, C, Keras",
    description: "Developed small scaled autonomous car to travel alongside the sidewalks within my neighborhood. Applied computer vision algorithms for car to filter image and use to develop artificial lanes to travel within",
    url: "https://github.com/ajand2003/laneDetectionPiCar/blob/master/RaspberryPiCarProject.pdf",
  },
  {
    image: DISCORD,
    name: "Discode",
    technologyUsed: "Java, Discord Bot API, Python",
    description: "  The Discode Bot centralizes competitive programming resources, and also makes it easy to search for CodeForces problems of the right level using the CodeForces API. This, along with the fact that its commands are easily performed through interaction over Discord means it should make competitive programming an easier interest to pursue.",
    url: "https://devpost.com/software/discode-competitive-programming-helper",
  },
];

export const SKILLS = [
  { name: "ReactJs", initialRating: 4 },
  { name: "React-Native", initialRating: 4 },
  { name: "JavaScript", initialRating: 4 },
  { name: "Nodejs", initialRating: 3 },
  { name: "Koajs", initialRating: 3 },
  { name: "Html", initialRating: 4 },
  { name: "CSS", initialRating: 4 },
  { name: "Unit-Testing(Jest)", initialRating: 3 },
  { name: "TypeScript", initialRating: 4 },
  { name: "Git", initialRating: 4 },
  { name: "C++", initialRating: 4 },
  { name: "C", initialRating: 4 },
  { name: "Assembly", initialRating: 4 },
  { name: "Python", initialRating: 4 },
  { name: "Java", initialRating: 4 },
  { name: "C#", initialRating: 4 },
];

export const TOOLS = [,"ASP.NET", "Pytorch", "Git", "Bash", "Angular", "Postman", "Spring", "Linux", "MongoDB", "AWS", "Tensorflow"]
